
.ant-upload-select-picture-card i {
    color: #999;
    font-size: 32px;
  }
  
  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
  ::-webkit-file-upload-button {
    background: white;
    border: 1px solid #ccc;
    padding: 6px 12px;
  }
  