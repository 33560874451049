// [data-theme="compact"] .site-collapse-custom-collapse .site-collapse-custom-panel,
// .site-collapse-custom-collapse .site-collapse-custom-panel {
//   margin-bottom: 24px;
//   overflow: hidden;
//   background: #f7f7f7;
//   border: 0px;
//   border-radius: 2px;
// }

.gris-bold {
  color: #7F7F7F;
  font-weight: 700;
}
.background-gris{
  background:#F4F6F6;
 
}

.actions-back{
  .ant-card-actions{
    background:#F4F6F6;
  }
}
