
.login-form {
    max-width: 500px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 40px !important;
}

.login-form-forgot {
    float: right;
}

.ant-col-rtl .login-form-forgot {
    float: left;
}

.login-form-button {
    width: 100%;
}

.noti {
    .ant-card-head {
        background-color: #E1F2FB !important;
    }
    .ant-card-head-title {
        font-size: 16px;
        font-weight: bold;
    }
    .detalles {
        color: rgb(97, 97, 97);
    }
}

@media (max-width: 767px) {
    .login-form {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
    }
}