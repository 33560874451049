.card-head{
    .ant-card-head{
        text-align: center !important;

        background: rgb(0,21,41) !important;
        color: white;
    }
  
}
.steps-content {
    min-height: 200px;
    margin-top: 16px;
    padding-top: 80px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
  }
  
  .steps-action {
    margin-top: 24px;
  }
  // .preview{
    .ant-upload-list-picture-card .ant-upload-list-item-info::before{
      background-color: rgb(0 0 0 / 0%) !important;
    }
  // }