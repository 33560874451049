/* Columna completa */
.category-col {
  text-align: center;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.category-col:hover {
  background-color: #f0f0f0; /* Cambia el fondo al hacer hover */
}

/* Imagen dentro de la columna */
.category-image {
  transition: transform 0.3s ease;
  border-radius: 8px;
}

.category-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure the content takes up the full height */
  padding: 10px; /* Optional: Adjust spacing */
}

.image-container {
  width: 100%;
  padding-top: 100%; /* Makes the container a square */
  position: relative;
  overflow: hidden;
}

.category-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the square container, cropping if necessary */
}

.category-footer {
  margin-top: auto; /* Pushes the content to the bottom */
  text-align: center; /* Optional: Aligns text at the center */
}

.category-col:hover .category-image {
  transform: scale(1.1); /* Solo agranda la imagen */
}

/* Título (sin cambios, pero lo puedes personalizar) */
.category-title {
  color: #000;
  margin-top: 8px;
}

.category-box {
  height: 40px;
  width: 100%;
  line-height: 1;
  padding: 10px 15px;
  background-color: #001529;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
  color: white;
  white-space: nowrap;
  text-align: center;

  &:hover {
    background-color: rgb(244, 131, 9);
  }
}

.breadcrumb-item {
  cursor: pointer;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.breadcrumb-item:hover {
  color: #1890ff;
  text-decoration: underline;
}

//PRODUCT GALLERY
.product-gallery {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #ffffff;
}

.product-gallery h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.product-container {
  display: flex;
  overflow-x: auto;
  gap: 16px;
  padding-bottom: 10px;
  scroll-snap-type: x mandatory;
  //overflow: hidden;
}

.scroll-button {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.product-container {
  display: flex;
  transition: transform 0.3s ease; /* Smooth transition when scrolling */
}

.product-card {
  flex: 0 0 auto;
  width: 200px;
  scroll-snap-align: start;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensures content aligns at the top */
  height: 100%;
}

.card-meta {
  margin-bottom: auto; /* Ensures the text stays at the top */
}

.card-footer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-size: 16px;
  font-weight: bold;
  color: #1a73e8;
}

.product-container::-webkit-scrollbar {
  height: 8px;
}

.product-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}


