
    .card_style{
        .ant-card-head{
            // border:1px solid #0025f4 !important;
            // color:white !important;
            // border-radius:10px !important;
            text-align:center !important;
            height: 60px !important;
        }        
    }
   .divider_style{
       font-weight: 700 !important;
       border-top-color: rgba(109, 107, 107, 0.322) !important;
       margin-bottom: 25px !important;
   }
   .icon_style{
       font-size: 25px;
     
   }
