.inputQty {
    border: none;
    text-align: center;
    //    size: 1rem !important;
}
.meta_title {
    .ant-card-meta-title {
        font-size: 15px;
    }
}

// .ant-modal-header {
//     background-color: aliceblue !important;
// }
