#components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }
  
  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
  
  .header{
    background: #fff;
    float: right !important;
  }
  .site-layout-background{
    background: #fff;
  }

  // .ant-input-group-wrapper{
  //   vertical-align: middle !important;
  // }

  .avatar{
    background: white !important;
    color: black !important;    
}
.ant-menu-item .anticon, .ant-menu-submenu-title .anticon{
    font-size:20px !important;
}

.footer_cart{  
     > div{
         display: flex;
         align-items: center;
         justify-content: space-between;         
     }
     button{
         color: white;
         background-color: #319cda;
         width: 100%;         
     }
}
.divider_link{
  margin: 0px 0 !important;
}
// .row{
//  flex-flow: unset !important;
// }